import './ForSale.css';
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate} from 'react-router-dom';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import arrow_icon from "../../imgs/icon_arrow.png";
import arrow_icon_left from "../../imgs/icon_arrow_left.png";
import calendar from "../../imgs/calendar.png";
import milage from "../../imgs/races.png";
import star from "../../imgs/star.png";
import star_filled from "../../imgs/star_filled.png";

import { getPriceNumber, showHoursOrKm, translateText, mapFolderIdToIndex, formatPrice } from './utils';
import { checkIfFavorites, handleFavoriteToggle } from './favorites.js';

import { AdditionalCheckboxes, TypeCheckbox, CountyCheckbox, BrandCheckbox } from './Filter/filter-checkbox-element.js';

const ForSale = ({ currentLanguage, loggedInUser, isMenuOpen, objects, firebaseConfig }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const passedType = location.state?.type;
  const passedSubType = location.state?.subType;
  const passedSearchText = location.state?.searchInput;
  const passedLocalstorageSearch = location.state?.useLocalStorage || false;

  const [searchText, setSearchText] = useState(passedSearchText ? passedSearchText : '');
  const [selectedCounties, setSelectedCounties] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(5000000);
  const [minYear, setMinYear] = useState(1950);
  const [maxYear, setMaxYear] = useState(2024);
  const [selectedTypes, setSelectedTypes] = useState(passedType ? [passedType] : []);
  const [subType, setSubType] = useState(passedSubType ? [passedSubType] : []);
  const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);
  //const [filteredObjects, setFilteredObjects] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [objectsData, setObjectsData] = useState(objects);
  const [favorits, setFavorites] = useState([]);
  const { state } = location;  
  const [sortOption, setSortOption] = useState('newest');


  // Initialize Firestore
  firebase.initializeApp(firebaseConfig); // Replace firebaseConfig with your Firebase configuration
  const db = firebase.firestore();

  // Initialize filteredObjects with state if it exists, otherwise an empty array
  const [filteredObjects, setFilteredObjects] = useState(state?.filteredObjects || []);

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const sortedObjects = [...filteredObjects];

  switch (sortOption) {
    case 'priceLowToHigh':
        sortedObjects.sort((a, b) => parseFloat(a.price.replace(/\s/g, '')) - parseFloat(b.price.replace(/\s/g, '')));
        break;
    case 'priceHighToLow':
        sortedObjects.sort((a, b) => parseFloat(b.price.replace(/\s/g, '')) - parseFloat(a.price.replace(/\s/g, '')));
        break;
    case 'yearOldToNew':
        sortedObjects.sort((a, b) => a.year - b.year);
        break;
    case 'yearNewToOld':
        sortedObjects.sort((a, b) => b.year - a.year);
        break;
    default: // 'newest'
        sortedObjects.reverse(); // Assuming objects are sorted by date initially
        break;
  }

  useEffect(() => {
    // Store each state value in localStorage
    if (passedLocalstorageSearch === false) {
      localStorage.setItem('searchText', searchText);
      localStorage.setItem('selectedCounties', JSON.stringify(selectedCounties));
      localStorage.setItem('selectedBrands', JSON.stringify(selectedBrands));
      localStorage.setItem('minPrice', minPrice);
      localStorage.setItem('maxPrice', maxPrice);
      localStorage.setItem('minYear', minYear);
      localStorage.setItem('maxYear', maxYear);
      localStorage.setItem('selectedTypes', JSON.stringify(selectedTypes));
      localStorage.setItem('subType', JSON.stringify(subType));
      localStorage.setItem('sortOption', sortOption);
    }
  }, [
    searchText, 
    selectedCounties, 
    selectedBrands, 
    minPrice, 
    maxPrice, 
    minYear, 
    maxYear, 
    selectedTypes, 
    subType, 
    sortOption
  ]);

  useEffect(() => {
    // Check if we should load from localStorage
    if (passedLocalstorageSearch === true) {
      const storedSearchText = localStorage.getItem('searchText');
      const storedSelectedCounties = localStorage.getItem('selectedCounties');
      const storedSelectedBrands = localStorage.getItem('selectedBrands');
      const storedMinPrice = localStorage.getItem('minPrice');
      const storedMaxPrice = localStorage.getItem('maxPrice');
      const storedMinYear = localStorage.getItem('minYear');
      const storedMaxYear = localStorage.getItem('maxYear');
      const storedSelectedTypes = localStorage.getItem('selectedTypes');
      const storedSubType = localStorage.getItem('subType');
      const storedSortOption = localStorage.getItem('sortOption');

      if (storedSearchText) setSearchText(storedSearchText);
      if (storedSelectedCounties) setSelectedCounties(JSON.parse(storedSelectedCounties));
      if (storedSelectedBrands) setSelectedBrands(JSON.parse(storedSelectedBrands));
      if (storedMinPrice) setMinPrice(Number(storedMinPrice));
      if (storedMaxPrice) setMaxPrice(Number(storedMaxPrice));
      if (storedMinYear) setMinYear(Number(storedMinYear));
      if (storedMaxYear) setMaxYear(Number(storedMaxYear));
      if (storedSelectedTypes) setSelectedTypes(JSON.parse(storedSelectedTypes));
      if (storedSubType) setSubType(JSON.parse(storedSubType));
      if (storedSortOption) setSortOption(storedSortOption);

      navigate(location.pathname, { replace: true, state: { ...location.state, useLocalStorage: false } });
    }
  }, [location.state]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        // Reference to the GCS folder
        const storageRef = firebase.storage().ref();
        
        // List all the folders in the root directory
        const folders = await storageRef.listAll();
        
        // Create an array of promises for fetching images in parallel
        const imagePromises = folders.prefixes.map(async (folderRef) => {
          const result = await folderRef.listAll();
          
          // If there are items in the folder, get the download URL for the first image
          if (result.items.length > 0) {
            return result.items[0].getDownloadURL();
          }
          return null; // No images found in this folder
        });

        // Wait for all promises to resolve
        const urls = await Promise.all(imagePromises);
        
        // Filter out any null values (folders with no images)
        const filteredUrls = urls.filter((url) => url !== null);
        
        // Update the state with fetched image URLs
        setImageUrls(filteredUrls);
      } catch (error) {
        console.error('Error fetching images:', error);
      } 
    };

    fetchImages();
  }, []);

  useEffect(() => {
    if (filteredObjects.length === 0){
      // filteredObjects is empty, perform some action if needed
      handleSearch();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when this component mounts
  }, []);

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        // Check if loggedInId exists in local storage
        const loggedInId = localStorage.getItem('loggedInId');
        if (!loggedInId) return;
  
        const userRef = firebase.firestore().collection('users').doc(loggedInId);
        const doc = await userRef.get();
  
        if (doc.exists) {
          const userData = doc.data();
          const userFavorites = userData.favorites || [];
          setFavorites(userFavorites);
        }
      } catch (error) {
        console.log('Error retrieving favorites:', error);
      }
    };
  
    fetchFavorites();
  }, []);

  const toggleSearchForm = () => {
    setIsSearchFormVisible(!isSearchFormVisible);
  };

  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    
    const filteredObjects = objectsData.Objects.filter((obj) => {

      const isActive = obj.status === 'active' || obj.status === 'sold (Not Delivered)';

      
      // Check if the object title or text contains the search text
      const isMatch =
        obj.title.toLowerCase().includes(searchText.toLowerCase()) ||
        obj.text.toLowerCase().includes(searchText.toLowerCase());
  
      // Check if the object type is selected
      const isTypeMatch = 
        selectedTypes.length === 0 || selectedTypes.includes(obj.type);

      const isSubTypeMatch = 
        subType.length === 0 || subType.includes(obj.subType);
  
      const isSelectedCounty =
        selectedCounties.length === 0 || selectedCounties.includes(obj.county);

      const isSelectedBrand =
        selectedBrands.length === 0 || selectedBrands.includes(obj.brand);
  
      // Check if the object price is within the specified range
      const isPriceMatch =
        (minPrice === '' || getPriceNumber(obj.price) >= Number(minPrice)) &&
        (maxPrice === '' || getPriceNumber(obj.price) <= Number(maxPrice));
  
      const isYearMatch = 
        (minYear === '' || Number(obj.year) >= Number(minYear)) &&
        (maxYear === '' || Number(obj.year) <= Number(maxYear));

      return (
        isActive &&
        isMatch &&
        isYearMatch &&
        isTypeMatch &&
        isPriceMatch &&
        isSelectedCounty &&
        isSelectedBrand &&
        isSubTypeMatch
      );
    }); 
    setFilteredObjects(filteredObjects);
  };

  useEffect(() => {
    handleSearch();
  }, [searchText, selectedTypes, subType, selectedCounties, selectedBrands, minPrice, maxPrice, minYear, maxYear, objectsData]);

  const handleAdditionalCheckboxChange = (type) => {
    if (subType.includes(type)) {
      setSubType(subType.filter((subType) => subType !== type));
    } else {
      setSubType([...subType, type]);
    }
  };

  const handleMinPriceChange = (e) => {
    const value = Math.min(Math.max(0, parseInt(e.target.value, 10)), maxPrice - 1000);
    setMinPrice(value);
  };

  const handleMaxPriceChange = (e) => {
    const value = Math.max(parseInt(e.target.value, 10), minPrice + 1000);
    setMaxPrice(value);
  };

  const handleMinYearChange = (e) => {
    const value = Math.min(Math.max(1950, parseInt(e.target.value, 10)), maxYear - 1);
    setMinYear(value);
  };

  const handleMaxYearChange = (e) => {
    const value = Math.max(parseInt(e.target.value, 10), minYear + 1);
    setMaxYear(value);
  };

  return (
    <div>
      <h3 className="for-sale-header">{translateText("ForSale", currentLanguage)}</h3>
      <div className={`filter-btn ${isMenuOpen ? 'menu-open' : ''}`}>
        <button onClick={toggleSearchForm} className={`btn-toggle-search-form ${isSearchFormVisible ? 'closed' : 'open'}`}>
          <img className="filter-menu-arrow" src={isSearchFormVisible ? arrow_icon_left : arrow_icon} />
          <p>{translateText("Filter", currentLanguage)}</p>
        </button>
      </div>
      <div className={`form-container ${isSearchFormVisible ? 'open' : 'closed'}`}>
      <form onSubmit={handleSearch} className="search-form">
        <input className="search-form-text" type="text" placeholder="Search..." value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        <div className="search-form-types">
          <h4>{translateText("Types", currentLanguage)}</h4>
          <TypeCheckbox 
            Category = "agricultural"
            currentLanguage = {currentLanguage}
            setSelectedTypes = {setSelectedTypes}
            selectedTypes = {selectedTypes}
            translateText = {translateText}
            filteredObjects = {filteredObjects}
          />
          {selectedTypes.includes('agricultural') && (
            <div className="additional-options-search-filter">
              <AdditionalCheckboxes
                subTypeKey="TraktorOgRedskap"
                labelKey="Agru1"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Transportutstyr"
                labelKey="Agru2"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Annet-agricultural"
                labelKey="Agru3"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
            </div>
          )}
          <TypeCheckbox 
            Category = "construction"
            currentLanguage = {currentLanguage}
            setSelectedTypes = {setSelectedTypes}
            selectedTypes = {selectedTypes}
            translateText = {translateText}
            filteredObjects = {filteredObjects}
          />
          {selectedTypes.includes("construction") && (
            <div className="additional-options-search-filter">
              <AdditionalCheckboxes
                subTypeKey="Dumper"
                labelKey="Const1"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Gravemaskin"
                labelKey="Const2"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Hjullaster"
                labelKey="Const3"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="KnuseOgSiktemaskin"
                labelKey="Const4"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Minigraver"
                labelKey="Const5"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Truck"
                labelKey="Const6"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="KontainereOgArbeidsbrakker"
                labelKey="Const7"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Annet-construction"
                labelKey="Const8"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
            </div>
          )}
          <TypeCheckbox 
            Category = "lightTruck"
            currentLanguage = {currentLanguage}
            setSelectedTypes = {setSelectedTypes}
            selectedTypes = {selectedTypes}
            translateText = {translateText}
            filteredObjects = {filteredObjects}
          />
          {selectedTypes.includes("lightTruck") && (
            <div className="additional-options-search-filter">
              <AdditionalCheckboxes
                subTypeKey="Minibuss"
                labelKey="Ligh1"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Turbuss"
                labelKey="Ligh2"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
            </div>
          )}
          <TypeCheckbox 
            Category = "heavyVehicles"
            currentLanguage = {currentLanguage}
            setSelectedTypes = {setSelectedTypes}
            selectedTypes = {selectedTypes}
            translateText = {translateText}
            filteredObjects = {filteredObjects}
          />
          {selectedTypes.includes("heavyVehicles") && (
            <div className="additional-options-search-filter">
              <AdditionalCheckboxes
                subTypeKey="Betongbil"
                labelKey="Heav1"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Chassie"
                labelKey="Heav2"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Henger"
                labelKey="Heav3"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Kranbil"
                labelKey="Heav4"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Krokløft"
                labelKey="Heav5"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Planbil"
                labelKey="Heav6"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Skapbil"
                labelKey="Heav7"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Spesialkjøretøy"
                labelKey="Heav8"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Tankbil"
                labelKey="Heav9"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Tippbil"
                labelKey="Heav10"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Trekvogn"
                labelKey="Heav11"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Tømmerbil"
                labelKey="Heav12"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              <AdditionalCheckboxes
                subTypeKey="Annet-heavy"
                labelKey="Heav14"
                subType={subType}
                handleAdditionalCheckboxChange={handleAdditionalCheckboxChange}
                translateText={translateText}
                currentLanguage={currentLanguage}
                filteredObjects = {filteredObjects}
                objects = {objectsData}
              />
              {/*
              <label>
                <input
                  type="checkbox"
                  checked={subType.includes('Buss')}
                  onChange={() => handleAdditionalCheckboxChange('Buss')}
                />
                {translateText("Heav13", currentLanguage)}
              </label>
              */}
            </div>
          )}
        </div>
        <div>
          <h4>{translateText("County", currentLanguage)}</h4>
            <div className="county-search-list">
              <CountyCheckbox
                selectedCounties = {selectedCounties}
                setSelectedCounties = {setSelectedCounties}
                county = "Finnmark"
                filteredObjects = {filteredObjects}
              />
              <CountyCheckbox
                selectedCounties = {selectedCounties}
                setSelectedCounties = {setSelectedCounties}
                county = "Troms"
                filteredObjects = {filteredObjects}
              />
              <CountyCheckbox
                selectedCounties = {selectedCounties}
                setSelectedCounties = {setSelectedCounties}
                county = "Nordland"
                filteredObjects = {filteredObjects}
              />
              <CountyCheckbox
                selectedCounties = {selectedCounties}
                setSelectedCounties = {setSelectedCounties}
                county = "Trøndelag"
                filteredObjects = {filteredObjects}
              />
              <CountyCheckbox
                selectedCounties = {selectedCounties}
                setSelectedCounties = {setSelectedCounties}
                county = "Møre og Romsdal"
                filteredObjects = {filteredObjects}
              />
              <CountyCheckbox
                selectedCounties = {selectedCounties}
                setSelectedCounties = {setSelectedCounties}
                county = "Vestland"
                filteredObjects = {filteredObjects}
              />
              <CountyCheckbox
                selectedCounties = {selectedCounties}
                setSelectedCounties = {setSelectedCounties}
                county = "Rogaland"
                filteredObjects = {filteredObjects}
              />
              <CountyCheckbox
                selectedCounties = {selectedCounties}
                setSelectedCounties = {setSelectedCounties}
                county = "Agder"
                filteredObjects = {filteredObjects}
              />
              <CountyCheckbox
                selectedCounties = {selectedCounties}
                setSelectedCounties = {setSelectedCounties}
                county = "Vestfold"
                filteredObjects = {filteredObjects}
              />
              <CountyCheckbox
                selectedCounties = {selectedCounties}
                setSelectedCounties = {setSelectedCounties}
                county = "Akershus"
                filteredObjects = {filteredObjects}
              />
              <CountyCheckbox
                selectedCounties = {selectedCounties}
                setSelectedCounties = {setSelectedCounties}
                county = "Oslo"
                filteredObjects = {filteredObjects}
              />
              <CountyCheckbox
                selectedCounties = {selectedCounties}
                setSelectedCounties = {setSelectedCounties}
                county = "Innlandet"
                filteredObjects = {filteredObjects}
              />
              <CountyCheckbox
                selectedCounties = {selectedCounties}
                setSelectedCounties = {setSelectedCounties}
                county = "Østfold"
                filteredObjects = {filteredObjects}
              />
              <CountyCheckbox
                selectedCounties = {selectedCounties}
                setSelectedCounties = {setSelectedCounties}
                county = "Buskerud"
                filteredObjects = {filteredObjects}
              />
              <CountyCheckbox
                selectedCounties = {selectedCounties}
                setSelectedCounties = {setSelectedCounties}
                county = "Telemark"
                filteredObjects = {filteredObjects}
              />
            </div>
        </div>
        <h4>{translateText("Brand", currentLanguage)}</h4>
        <div className="county-search-list">
          <BrandCheckbox
            Brand = "Volvo"
            setSelectedBrands = {setSelectedBrands}
            selectedBrands = {selectedBrands}
            filteredObjects = {filteredObjects}  
          />
          <BrandCheckbox
            Brand = "Scania"
            setSelectedBrands = {setSelectedBrands}
            selectedBrands = {selectedBrands}  
            filteredObjects = {filteredObjects}
          />
          <BrandCheckbox
            Brand = "CAT"
            setSelectedBrands = {setSelectedBrands}
            selectedBrands = {selectedBrands}  
            filteredObjects = {filteredObjects}
          />
          <BrandCheckbox
            Brand = "Toyota"
            setSelectedBrands = {setSelectedBrands}
            selectedBrands = {selectedBrands}  
            filteredObjects = {filteredObjects}
          />
          <BrandCheckbox
            Brand = "Annet"
            setSelectedBrands = {setSelectedBrands}
            selectedBrands = {selectedBrands}  
            filteredObjects = {filteredObjects}
          />
        </div>
        <h4>{translateText("Price", currentLanguage)}</h4>
        <div className="slider-container">
          <input
            type="range"
            min="0"
            max="5000000"
            value={minPrice}
            onChange={handleMinPriceChange}
            className="slider slider-min"
            id="minRange"
            step="1000"
          />
          <input
            type="range"
            min="0"
            max="5000000"
            value={maxPrice}
            onChange={handleMaxPriceChange}
            className="slider slider-max"
            id="maxRange"
            step="1000"
          />
        </div>
        <div className="slider-textbox">
          <div>
            <label>
            {translateText("Minimum", currentLanguage)}
              <input 
                className="search-form-price" 
                type="number" value={minPrice} 
                onChange={handleMinPriceChange}
                placeholder="NOK"
              />
            </label>
          </div>
          <div>
            <label>
            {translateText("Maximum", currentLanguage)}
              <input 
                className="search-form-price" 
                type="number" value={maxPrice} 
                onChange={(e) => setMaxPrice(e.target.value)} 
                placeholder="NOK"
              />
            </label>
          </div>
        </div>
        <h4>{translateText("Årsmodell", currentLanguage)}</h4>
        <div className="slider-container">
          <input
            type="range"
            min="1950"
            max="2024"
            value={minYear}
            onChange={handleMinYearChange}
            className="slider slider-min"
            id="minRange"
          />
          <input
            type="range"
            min="1950"
            max="2024"
            value={maxYear}
            onChange={handleMaxYearChange}
            className="slider slider-max"
            id="maxRange"
          />
        </div>
        <div className="slider-textbox">
          <div>
            <label>
            {translateText("From", currentLanguage)}
              <input
                className="search-form-price" 
                type="number"
                value={minYear}
                onChange={(e) => setMinYear(e.target.value)} 
              />
            </label>
          </div>
          <div>
            <label>
            {translateText("To", currentLanguage)}
              <input
                className="search-form-price" 
                type="number"
                value={maxYear}
                onChange={(e) => setMaxYear(e.target.value)} 
              />
            </label>
          </div>
        </div>

        <button 
          className="search-form-search-btn" 
          type="submit"
        >
          {translateText("Search", currentLanguage)}
        </button>
        
      </form>
      </div>

      <div>
  {filteredObjects.length > 0 ? (
    <div className="search-results">
        <h4>{filteredObjects.length} treff</h4>
      <div className="sort-filter-container">
        <p>Sorter etter</p>
        <select value={sortOption} onChange={handleSortChange}>
          <option value="newest">Nyeste</option>
          <option value="priceLowToHigh">Pris Lav-Høy</option>
          <option value="priceHighToLow">Pris Høy-Lav</option>
          <option value="yearOldToNew">Årsmodell Gammel-Ny</option>
          <option value="yearNewToOld">Årsmodell Ny-Gammel</option>
        </select>
      </div>
      <ul>
        <div className={`search-results-container ${isSearchFormVisible === true ? 'search-results-container-filter-open' : ''}`}>
          {sortedObjects.map((obj) => (
            <div className="search-result-container" key={obj.ObjId}>
              <div className="search-favourite-container">
                <input
                  className={`search-favourite ${isMenuOpen ? 'menu-open' : ''}`}
                  name="isFavourite"
                  type="checkbox"
                  checked={checkIfFavorites(obj.ObjId, favorits)}
                  onChange={(e) => {
                    handleFavoriteToggle(obj.ObjId, firebase, setFavorites);
                  }}
                />
                <p className="search-favourite-icon" role="img" aria-label="Heart">
                  {checkIfFavorites(obj.ObjId, favorits) ? <img src={star_filled}/> : <img src={star}/>}
                </p>
              </div>
              <Link className="search-result" to={obj.ObjId}>
                <img
                  src={imageUrls[mapFolderIdToIndex(obj.ObjId)]}
                  alt={`ForSale_${obj.ObjId}.jpeg`}
                  className="img-for-sale"
                  loading="lazy"
                />
                {obj.status === "sold (Not Delivered)" && (
                  <div className="img-for-sale-tag">
                    <p>{translateText("Sold", currentLanguage)}</p>
                  </div>
                )}
                <div className="search-info-container">
                  <h3 className="search-obj-title">{obj.title}</h3>
                  {obj.status !== "sold (Not Delivered)" && (
                      <p className="search-price">{formatPrice(obj.price)},- NOK</p>
                    )}
                  <p className="search-place">
                    <i>{obj.place}, {obj.county}, {obj.country}</i>
                  </p>
                  <p className="search-ref">Ref nr.{obj.ObjId}</p>
                  <div className="object-sub-info-container">
                    <div>
                      <img className="search-result-info-icon" src={calendar}/>
                      <p>{obj.year}</p>
                    </div>
                    <div>
                      {obj.kmtimer && (
                        <img className="search-result-info-icon" src={milage} alt="Milage Icon"/>
                      )}
                      <p>{showHoursOrKm(obj)}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </ul>
    </div>
  ) : (
    <p className="ingen-treff">{translateText("NoHits", currentLanguage)}</p>
    )}
  </div>
  </div>
  );
};


export default ForSale;