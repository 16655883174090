import React, { useEffect, useState } from 'react';
import './Ringelister.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { doc, getDoc, updateDoc} from 'firebase/firestore';

import TrondelagData from "./Fylker/Trøndelag.json";
import NordlandData from "./Fylker/Nordland.json";
import RogalandData from "./Fylker/Rogaland.json";
import AgderData from "./Fylker/Agder.json";
import AkershusData from "./Fylker/Akershus.json";
import BuskerudData from "./Fylker/Buskerud.json";
import FinnmarkData from "./Fylker/Finnmark.json";
import InnlandetData from "./Fylker/Innlandet.json";
import TelemarkData from "./Fylker/Telemark.json";
import MøreOgRomsdalData from "./Fylker/Møre og Romsdal.json";
import TromsData from "./Fylker/Troms.json";
import VestfoldData from "./Fylker/Vestfold.json";
import VestlandData from "./Fylker/Vestland.json";
import OstfoldData from "./Fylker/Østfold.json";
import OsloData from "./Fylker/Oslo.json";

import getResponsStyle from "./functions/getResponsStyle";
import getFylkeFromKommunenummer from "./functions/getFylkeFromKommunenummer";
import getDateAndTime from "./functions/getDateAndTime";
import toastStyle from "./functions/toastStyle";
import fetchPhoneNumber from "./functions/fetchPhoneNumber";

const Ringelister = (firebaseConfig) => {
  const [showToast, setShowToast] = useState(false);

  const [entityList, setEntityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [saleObjects, setSaleObjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [selectedSearchTerm, setSelectedSearchTerm] = useState('');
  const [selectedCounties, setSelectedCounties] = useState([]);
  const [selectedCompanyTypes, setSelectedCompanyTypes] = useState(["AS"]);
  const [selectedResponseFilter, setSelectedResponseFilter] = useState(["IkkeSvar","Positiv","Nøytral"]);
  const [selectedKommune, setSelectedKommune] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const [selectedResponse, setSelectedResponse] = useState(null);
  const [textareaValue, setTextareaValue] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const db = firebase.firestore();


  useEffect(() => {
    const readCostumersFromFirebase = async () => {
      try {
        const CostumersCollection = await db.collection('kundekort')
          .where('OrgForm', '==', 'AS')
          .get();
        const entities = CostumersCollection.docs.map(doc => doc.data());
        setEntityList(entities);
      } catch (error) {
        console.error('Error fetching data from kundekort collection:', error);
      }
    };

    // Call the function when the component mounts
    readCostumersFromFirebase();
  }, []); // The empty dependency array ensures that this effect runs only once on mount


useEffect(() => {
  const fetchSaleObjects = async () => {
    try {
      const snapshot = await firebase.firestore().collection('saleObjectsAdmin').get();
      const saleObjectsData = [];
      snapshot.forEach(doc => {
        const data = doc.data();
        // Check if the document has the OrgNr field
        if (data && data.OrgNr) {
          // Store OrgNr and document ID in an object
          saleObjectsData.push({ OrgNr: data.OrgNr, docId: doc.id});
        }
      });
      // Update state with the fetched data
      setSaleObjects(saleObjectsData);
    } catch (error) {
      console.error('Error fetching sale objects:', error);
    }
    
  };

  fetchSaleObjects();
}, []);

  const writeToFirebase = async () => {
    // Assuming 'entityList' is an array of objects, and each object has a 'navn' property
    for (const entity of entityList) {
      const docId = (entity.navn ?? "Unknown").toString().replace(/\//g, " ");

    // Check if the document already exists
    const existingDoc = await db.collection('kundekort').doc(docId).get();

    if (existingDoc.exists) {
      console.log(`Document with ID ${docId} already exists. Skipping...`);
      continue; // Skip to the next iteration
    }

      // Add your logic to extract the data you want to save to Firestore
      const dataToSave = {
        Navn: docId ?? "Unknown",
        OrgNr: entity.organisasjonsnummer ?? 'Unknown',
        OrgForm: entity.organisasjonsform?.kode ?? 'Unknown',
        StiftetDato: entity.stiftelsesdato ?? 'Unknown',
        Naeringskode: entity.naeringskode1?.beskrivelse ?? 'Unknown',
        AntallAnsatte: entity.antallAnsatte ?? 'Unknown',
        Adresse: entity.forretningsadresse?.adresse ?? 'Unknown',
        Kommune: entity.forretningsadresse?.kommune ?? 'Unknown',
        Kommunenummer: entity.forretningsadresse?.kommunenummer ?? 'Unknown',
        Konkurs: entity.konkurs ?? 'Unknown',
        UnderAvvikling: entity.underAvvikling ?? 'Unknown',
        UnderTvangsavvikling: entity.underTvangsavviklingEllerTvangsopplosning ?? 'Unknown',
        Respons: "Nøytral",
        KontaktLogg: [],
        Tlf: ""
      };

      try {
        // Write to Firestore
        await db.collection('kundekort').doc(docId).set(dataToSave);
        console.log(`Document with ID ${docId} written to Firestore.`);
      } catch (error) {
        console.error('Error writing to Firestore:', error);
      }
    }
  };

  const handleCallElementClick = async (entity) => {
    if (selectedEntity === entity){
      setSelectedEntity(null);
    }else{
    setSelectedEntity(entity); // Set the selected entity when a CallElement is clicked
    }
  };

  const fetchData = async (url) => {
    try {
      const response = await fetch(url);
      return response.json();
    } catch (error) {
      console.error('Error:', error);
      return [];
    }
  };

  const fetchRingelisteData = async () => {
    try {
      setLoading(true);
      // Initialize Firebase with your Firebase configuration
      //firebase.initializeApp(firebaseConfig); // Replace firebaseConfig with your Firebase configuration
      
      const firebaseRef = firebase.firestore().collection('ringeliste').doc('næringskode');
      // Fetch the document data
      const ringelisteData = await firebaseRef.get();
      // Extract an array of naeringskode field names
      const naeringskodeFields = ringelisteData.data().array; // Replace with your actual field names
      // Fetch data for each naeringskode field
      const fetchDataPromises = naeringskodeFields.map(async (fieldName) => {
        // Construct the URL with the extracted field name
        const apiUrl = `https://data.brreg.no/enhetsregisteret/api/enheter?naeringskode=${fieldName}&kommunenummer=1875&size=1000&sort=antallAnsatte,ASC`;

        // Fetch data using the constructed URL
        return fetchData(apiUrl);
      });

      // Wait for all data fetches to complete
      const dataArray = await Promise.all(fetchDataPromises);

      // Combine the results
      const combinedData = dataArray.flatMap((data) => data._embedded?.enheter || []);

      // Create a Set to track unique items
      const uniqueEntities = new Set();

      // Filter the combinedData to remove duplicates
      const filteredData = combinedData.filter(entity => {
        if (uniqueEntities.has(entity.organisasjonsnummer)) {
          // Duplicate found, return false to filter it out
          return false;
        }
        // Not a duplicate, add it to the Set and return true to keep it
        uniqueEntities.add(entity.organisasjonsnummer);
        return true;
      });
      
      const updatedData = filteredData.map(entity => {
        if (entity.forretningsadresse && entity.forretningsadresse.kommunenummer) {
          const kommuneStart = entity.forretningsadresse.kommunenummer.substring(0, 2);
          switch (kommuneStart) {
            case '15':
              entity.county = 'Møre og Romsdal';
              break;
            case '30':
              entity.county = 'Viken';
              break;
            case '31':
              entity.county = 'Østfold';
              break;
            case '32':
              entity.county = 'Akershus';
              break;
            case '33':
              entity.county = 'Buskerud';
              break;
            case '34':
              entity.county = 'Innlandet';
              break;
            case '38':
              entity.county = 'Vestfold og Telemark';
              break;
            case '39':
              entity.county = 'Vestfold';
              break;
            case '40':
              entity.county = 'Telemark';
              break;
            case '42':
              entity.county = 'Agder';
              break;
            case '46':
              entity.county = 'Vestland';
              break;
            case '50':
              entity.county = 'Trøndelag';
              break;
            case '54':
              entity.county = 'Troms og Finnmark';
              break;
            case '55':
              entity.county = 'Troms';
              break;
            case '56':
              entity.county = 'Finnmark';
              break;
            case '21':
              entity.county = 'Svalbard';
              break;
            default:
              entity.county = 'Unknown';
          }
        }
        return entity;
      });

      await setEntityList(updatedData);
      await writeToFirebase();
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  // ######### SEARCH FILTER ############ 
  // Filter entities based on selected counties
  const handleCountyCheckboxChange = (county) => {
    // Toggle the selected county
    setSelectedCounties((prevCounties) => 
      prevCounties.includes(county)
        ? prevCounties.filter((item) => item !== county)
        : [...prevCounties, county]
    );
  };

  const handleSaveInLacalstorage = (kommune) => {
    localStorage.setItem('selectedKommune', kommune);
  }

  const handleKommuneCheckboxChange = (kommune) => {
    // Convert kommune to uppercase
    const uppercaseKommune = kommune.toUpperCase();
  
    // Toggle the selected kommune
    setSelectedKommune((prevKommune) =>
      prevKommune.includes(uppercaseKommune)
        ? prevKommune.filter((item) => item !== uppercaseKommune)
        : [...prevKommune, uppercaseKommune]
    );

    handleSaveInLacalstorage(selectedKommune)
    // IKKE FERDIG
  };

  const handleOrgFormCheckboxChange = (type) => {
    // Toggle the selected company type
    setSelectedCompanyTypes((prevTypes) =>
      prevTypes.includes(type)
        ? prevTypes.filter((item) => item !== type)
        : [...prevTypes, type]
    );
  };

  const handleResponseCheckboxChange = (type) => {
    // Toggle selecteed respons type
    setSelectedResponseFilter((prevTypes) =>
      prevTypes.includes(type)
        ? prevTypes.filter((item) => item !== type)
        : [...prevTypes, type]
    );
  }

  // Filter entities based on selected counties and company types
  const filteredEntities = entityList.filter((entity) => {
    const countyMatch =
      selectedCounties.length === 0 ||
      selectedCounties.includes(getFylkeFromKommunenummer(entity.Kommunenummer));
    const kommuneMatch =
      selectedKommune.length === 0 || selectedKommune.includes(entity.Kommune);
    const typeMatch =
      selectedCompanyTypes.length === 0 || selectedCompanyTypes.includes(entity.OrgForm);
    const responseMatch =
      selectedResponseFilter.length === 0 || selectedResponseFilter.includes(entity.Respons);

    const nameMatch = selectedSearchTerm === '' ||
      entity.Navn.toLowerCase().includes(selectedSearchTerm.toLowerCase()) ||
      entity.Tlf.includes(selectedSearchTerm) ||
      entity.OrgNr.includes(selectedSearchTerm);

    // Return true if all conditions match
    return countyMatch && kommuneMatch && typeMatch && responseMatch && nameMatch;
  });

  // ##############################
  const handleUpdateRingeliste = () => {
    fetchRingelisteData();
    const currentDate = new Date().toLocaleDateString(); // Get the current date in the desired format
    setLastUpdated(`Sist oppdatert (${currentDate})`);
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  const handleResponseClick = (response) => {
    setSelectedResponse(response);
    // Add any other logic you want to execute when a response is clicked
  };

  const handleSubmitKontaktLogg = async (entity) => {
    try {
      // Log the formatted date and time
      console.log("submit");

      //const docRef = doc(db, 'kundekort', entity.Navn);
      const user = String(localStorage.loggedInUser);
      const time = String(getDateAndTime());
      const docRef = db.collection('kundekort').doc(entity.Navn);

      const doc = await docRef.get();
      // Prepare the data to be added to the kontaktlogg array
      const logEntry = [
        time,
        user, // Assuming you have the user ID in localStorage
        textareaValue,
      ];
  
      // Update the 'KontaktLogg' array with the new log entry
      const updatedKontaktLogg = [
        ...doc.data().KontaktLogg,
        ...logEntry,
      ];

      await docRef.update({
        KontaktLogg: updatedKontaktLogg, // Update the 'Respone' field (fixing the typo)
        Respons : selectedResponse
      });
  
      console.log('Log entry added to Firebase.');
      showAlert();
      setSelectedResponse(null);
      setTextareaValue("");

    } catch (error) {
      console.error('Error adding log entry to Firebase:', error);
    }
  };

  const handleMakeBooking = (entity) => {
    const Adresse = entity.Adresse;
    const OrgNr = entity.OrgNr;
    // Open a new window at /Bookinger
    const newWindow = window.open('/Bookinger', '_blank');

    // Post a message to the new window with the data
    if (newWindow) {
      newWindow.postMessage({ setSelectedChoice: 'Ny booking' }, window.location.origin);
    }

    console.log({ setSelectedChoice: 'Ny booking' });
    };

  const handleAddPhone = async (entity) => {
    const db = firebase.firestore();
  
    // Define the path to the document in Firestore
    const path = `kundekort/${entity.Navn}`;
  
    try {
      // Get the document
      const documentRef = doc(db, path);
      const documentSnapshot = await getDoc(documentRef);
  
      // Check if the document exists
      if (documentSnapshot.exists()) {
        // Update the document with the new phone number
        await updateDoc(documentRef, {
          Tlf: phoneNumber,
        });
  
        console.log('Phone number added successfully');
      } else {
        console.error('Document does not exist');
      }
    } catch (error) {
      console.log(phoneNumber)
      console.error('Error adding phone number:', error);
    }
  };

  const handleComplete = () => {
    console.log(selectedKommune)
    // IKKE FERDIG
  }

  const handleTextSearch = () => {
    console.log(searchTerm);
    setSelectedSearchTerm(searchTerm);
  };

  const showAlert = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 1000); // Adjust the duration as needed (in milliseconds)
  };

  return (
    <div>
      <h1 className="header-ringeliste">Ringeliste</h1>
      {showToast && (
        <div style={toastStyle}>
          <p>Lagret i kontaktlogg</p>
        </div>
      )}
      <div className="ringeliste-full-container">
        <div>
          <input 
            type="text" 
            placeholder="bedrift, org.nr eller tlf"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          >
          </input>
          <button onClick={() => handleTextSearch()}>
            Søk
          </button>
          <p className="filter-sub-menu">Geografi</p>
          <div className="call-regions">
            <label>
              <input
                type="checkbox"
                value="Østfold"
                checked={selectedCounties.includes("Østfold")}
                onChange={() => handleCountyCheckboxChange("Østfold")}
              />
              Østfold
            </label>
            {selectedCounties.includes('Østfold') && OstfoldData && (
              <div className="additional-checkboxes">
                {Object.entries(OstfoldData).map(([code, name]) => (
                  <label key={code}>
                    <input
                      type="checkbox"
                      value={code}
                      onChange={() => handleKommuneCheckboxChange(name)}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
            <label>
              <input
                type="checkbox"
                value="Akershus"
                checked={selectedCounties.includes("Akershus")}
                onChange={() => handleCountyCheckboxChange("Akershus")}
              />
              Akershus
            </label>
            {selectedCounties.includes('Akershus') && AkershusData && (
              <div className="additional-checkboxes">
                {Object.entries(AkershusData).map(([code, name]) => (
                  <label key={code}>
                    <input
                      type="checkbox"
                      value={code}
                      onChange={() => handleKommuneCheckboxChange(name)}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
            <label>
              <input
                type="checkbox"
                value="Buskerud"
                checked={selectedCounties.includes("Buskerud")}
                onChange={() => handleCountyCheckboxChange("Buskerud")}
              />
              Buskerud
            </label>
            {selectedCounties.includes('Buskerud') && BuskerudData && (
              <div className="additional-checkboxes">
                {Object.entries(BuskerudData).map(([code, name]) => (
                  <label key={code}>
                    <input
                      type="checkbox"
                      value={code}
                      onChange={() => handleKommuneCheckboxChange(name)}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
            <label>
              <input
                type="checkbox"
                value="Innlandet"
                checked={selectedCounties.includes("Innlandet")}
                onChange={() => handleCountyCheckboxChange("Innlandet")}
              />
              Innlandet
            </label>
            {selectedCounties.includes('Innlandet') && InnlandetData && (
              <div className="additional-checkboxes">
                {Object.entries(InnlandetData).map(([code, name]) => (
                  <label key={code}>
                    <input
                      type="checkbox"
                      value={code}
                      onChange={() => handleKommuneCheckboxChange(name)}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
            <label>
              <input
                type="checkbox"
                value="Vestfold"
                checked={selectedCounties.includes("Vestfold")}
                onChange={() => handleCountyCheckboxChange("Vestfold")}
              />
              Vestfold
            </label>
            {selectedCounties.includes('Vestfold') && VestfoldData && (
              <div className="additional-checkboxes">
                {Object.entries(VestfoldData).map(([code, name]) => (
                  <label key={code}>
                    <input
                      type="checkbox"
                      value={code}
                      onChange={() => handleKommuneCheckboxChange(name)}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
            <label>
              <input
                type="checkbox"
                value="Telemark"
                checked={selectedCounties.includes("Telemark")}
                onChange={() => handleCountyCheckboxChange("Telemark")}
              />
              Telemark
            </label>
            {selectedCounties.includes("Telemark") && TelemarkData && (
              <div className="additional-checkboxes">
                {Object.entries(TelemarkData).map(([code, name]) => (
                  <label key={code}>
                    <input
                      type="checkbox"
                      value={code}
                      onChange={() => handleKommuneCheckboxChange(name)}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
            <label>
              <input
                type="checkbox"
                value="Møre og Romsdal"
                checked={selectedCounties.includes("Møre og Romsdal")}
                onChange={() => handleCountyCheckboxChange("Møre og Romsdal")}
              />
              Møre og Romsdal
            </label>
            {selectedCounties.includes("Møre og Romsdal") && MøreOgRomsdalData && (
              <div className="additional-checkboxes">
                {Object.entries(MøreOgRomsdalData).map(([code, name]) => (
                  <label key={code}>
                    <input
                      type="checkbox"
                      value={code}
                      onChange={() => handleKommuneCheckboxChange(name)}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
            <label>
              <input
                type="checkbox"
                value="Agder"
                checked={selectedCounties.includes("Agder")}
                onChange={() => handleCountyCheckboxChange("Agder")}
              />
              Agder
            </label>
            {selectedCounties.includes('Agder') && AgderData && (
              <div className="additional-checkboxes">
                {Object.entries(AgderData).map(([code, name]) => (
                  <label key={code}>
                    <input
                      type="checkbox"
                      value={code}
                      onChange={() => handleKommuneCheckboxChange(name)}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
            <label>
              <input
                type="checkbox"
                value="Rogaland"
                checked={selectedCounties.includes("Rogaland")}
                onChange={() => handleCountyCheckboxChange("Rogaland")}
              />
              Rogaland
            </label>
            {selectedCounties.includes('Rogaland') && RogalandData && (
              <div className="additional-checkboxes">
                {Object.entries(RogalandData).map(([code, name]) => (
                  <label key={code}>
                    <input
                      type="checkbox"
                      value={code}
                      onChange={() => handleKommuneCheckboxChange(name)}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
            <label>
              <input
                type="checkbox"
                value="Oslo"
                checked={selectedCounties.includes("Oslo")}
                onChange={() => handleCountyCheckboxChange("Oslo")}
              />
              Oslo
            </label>
            {selectedCounties.includes('Oslo') && OsloData && (
              <div className="additional-checkboxes">
                {Object.entries(OsloData).map(([code, name]) => (
                  <label key={code}>
                    <input
                      type="checkbox"
                      value={code}
                      onChange={() => handleKommuneCheckboxChange(name)}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
            <label>
              <input
                type="checkbox"
                value="Vestland"
                checked={selectedCounties.includes("Vestland")}
                onChange={() => handleCountyCheckboxChange("Vestland")}
              />
              Vestland
            </label>
            {selectedCounties.includes('Vestland') && VestlandData && (
              <div className="additional-checkboxes">
                {Object.entries(VestlandData).map(([code, name]) => (
                  <label key={code}>
                    <input
                      type="checkbox"
                      value={code}
                      onChange={() => handleKommuneCheckboxChange(name)}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
            <label>
              <input
                type="checkbox"
                value="Trøndelag"
                checked={selectedCounties.includes("Trøndelag")}
                onChange={() => handleCountyCheckboxChange("Trøndelag")}
              />
              Trøndelag
            </label>
            {selectedCounties.includes('Trøndelag') && TrondelagData && (
              <div className="additional-checkboxes">
                {Object.entries(TrondelagData).map(([code, name]) => (
                  <label key={code}>
                    <input
                      type="checkbox"
                      value={code}
                      onChange={() => handleKommuneCheckboxChange(name)}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
            <label>
              <input
                type="checkbox"
                value="Nordland"
                checked={selectedCounties.includes("Nordland")}
                onChange={() => handleCountyCheckboxChange("Nordland")}
              />
              Nordland
            </label>
            {selectedCounties.includes('Nordland') && NordlandData && (
              <div className="additional-checkboxes">
                {Object.entries(NordlandData).map(([code, name]) => (
                  <label key={code}>
                    <input
                      type="checkbox"
                      value={code}
                      onChange={() => handleKommuneCheckboxChange(name)}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
            <label>
              <input
                type="checkbox"
                value="Troms"
                checked={selectedCounties.includes("Troms")}
                onChange={() => handleCountyCheckboxChange("Troms")}
              />
              Troms
            </label>
            {selectedCounties.includes('Troms') && TromsData && (
              <div className="additional-checkboxes">
                {Object.entries(TromsData).map(([code, name]) => (
                  <label key={code}>
                    <input
                      type="checkbox"
                      value={code}
                      onChange={() => handleKommuneCheckboxChange(name)}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
            <label>
              <input
                type="checkbox"
                value="Finnmark"
                checked={selectedCounties.includes("Finnmark")}
                onChange={() => handleCountyCheckboxChange("Finnmark")}
              />
              Finnmark
            </label>
            {selectedCounties.includes('Finnmark') && FinnmarkData && (
              <div className="additional-checkboxes">
                {Object.entries(FinnmarkData).map(([code, name]) => (
                  <label key={code}>
                    <input
                      type="checkbox"
                      value={code}
                      onChange={() => handleKommuneCheckboxChange(name)}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
            <label>
              <input
                type="checkbox"
                value="Svalbard"
                checked={selectedCounties.includes("Svalbard")}
                onChange={() => handleCountyCheckboxChange("Svalbard")}
              />
              Svalbard
            </label>
          </div>
          <p className="filter-sub-menu">Selskapstype</p>
          <div className="call-regions">
            <label>
              <input
                type="checkbox"
                value="AS"
                checked={selectedCompanyTypes.includes("AS")}
                onChange={() => handleOrgFormCheckboxChange("AS")}
              />
              AS
            </label>
          </div>
          <p className="filter-sub-menu">Respons</p>
          <div className="call-regions">
            <label>
              <input
                type="checkbox"
                value="Positiv"
                checked={selectedResponseFilter.includes("Positiv")}
                onChange={() => handleResponseCheckboxChange("Positiv")}
              />
              Positiv
            </label>
            <label>
              <input
                type="checkbox"
                value="Nøytral"
                checked={selectedResponseFilter.includes("Nøytral")}
                onChange={() => handleResponseCheckboxChange("Nøytral")}
              />
              Nøytral
            </label>
            <label>
              <input
                type="checkbox"
                value="Negativ"
                checked={selectedResponseFilter.includes("Negativ")}
                onChange={() => handleResponseCheckboxChange("Negativ")}
              />
              Negativ
            </label>
            <label>
              <input
                type="checkbox"
                value="IkkeSvar"
                checked={selectedResponseFilter.includes("IkkeSvar")}
                onChange={() => handleResponseCheckboxChange("IkkeSvar")}
              />
              Ikke Svar
            </label>
            <label>
              <input
                type="checkbox"
                value = {null}
                checked={selectedResponseFilter.includes(null)}
                onChange={() => handleResponseCheckboxChange(null)}
              />
              Null
            </label>
          </div>
          <div className="complete-ringeliste-btn" onClick={handleComplete}>
            <p>Ferdigringt (Ikke implementert)</p>
          </div>
          <div className="update-ringelist-btn" onClick={handleUpdateRingeliste}>
            <h4>Oppdater Ringeliste</h4>
            <p>{lastUpdated || "Klikk for å oppdatere"}</p>
          </div>
        </div>

        <div className="call-results">
          <p>{filteredEntities.length} Treff</p>
          {loading ? (
            <p>Loading...</p>
          ) : (
            filteredEntities
              .sort((a, b) => a.Navn.localeCompare(b.Navn))
              .slice(0, 500)
              .map(entity => (
            
              <div key={entity.organisasjonsnummer} className="callElement" >
                <div
                  className={`callElementHeader ${getResponsStyle(entity.Respons, entity.KontaktLogg)}`}
                  onClick={() => handleCallElementClick(entity)}
                >
                  <p className="callElementNavn">{entity.Navn}</p> 
                  <p className="callElementCounty">{getFylkeFromKommunenummer(entity.Kommunenummer)}</p>
                  {entity.OrgForm && (
                    <p className="callElementKode">{entity.OrgForm}</p>
                  )}
                  {entity.Adresse && (
                    <p className="callElementKommune">{entity.Kommune}</p>
                  )}
                  {entity.Naeringskode && (
                    <p className="callElementBeskrivelse">{entity.Naeringskode}</p>
                  )}
                </div>

                {selectedEntity === entity && (
                <div className="extra-info"> {/* Display extra info if selectedEntity matches */}
                  <div>
                    <div className="top-additional-info-container">
                      <div className="top-additional-info-general">
                        <p><span>Ansatte: </span>{entity.AntallAnsatte} personer</p>
                        <p><span>Registrert: </span>{entity.StiftetDato}</p>
                        <p><span>Org nr: </span>{entity.OrgNr}</p>
                        <p><span>Adresse: </span>{entity.Adresse}</p>
                        <div>
                        {entity.Tlf !== "" ? (
                          <p>Tlf: {entity.Tlf}</p>
                        ) : (
                          <div>
                            <input
                              type="text"
                              placeholder="Legg til telefonnummer"
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                            <button onClick={() => handleAddPhone(entity)}>Legg til</button>
                          </div>
                        )}
                        </div>
                        <p>Kontaktperson: </p>
                        <p>E-post: </p>
                      </div>
                      <iframe
                        id="inlineFramePhone"
                        title="Inline Frame phone"
                        width="400"
                        height="250"
                        src={`https://www.1881.no/?query=${entity.OrgNr}`}
                      ></iframe>
                      <iframe
                        id="inlineFramePhone"
                        title="Inline Frame phone"
                        width="400"
                        height="250"
                        src={`https://www.proff.no/bransjes%C3%B8k?q=${entity.OrgNr}`}
                      ></iframe>
                    </div>
                    <div className="sale-objects">
                      {saleObjects.map((saleObject) => (
                        // Check if OrgNr matches
                        saleObject.OrgNr === entity.OrgNr && (
                          <div className="sale-objects-for-customer">
                            <p key={saleObject.docId}>Selger Ref.Nr: {saleObject.docId}</p>
                            <a href={`https://www.vamu.no/for-sale/${saleObject.docId}`} target="_blank">Se annonse</a>
                          </div>
                        )
                      ))}
                    </div>
                    
                  </div>
                  {/* Add more extra information as needed */}
                  <div className="iframe-kontaktlogg-container">
                    <div>
                      <div className="respons-valg-meny"> {/* Set state based on buttonclick */}
                        <p
                          className={`respons-valg-positiv ${selectedResponse === 'Positiv' ? 'selected' : ''}`}
                          onClick={() => handleResponseClick('Positiv')}
                        >
                          Positiv
                        </p>
                        <p
                          className={`respons-valg-nøytral ${selectedResponse === 'Nøytral' ? 'selected' : ''}`}
                          onClick={() => handleResponseClick('Nøytral')}
                        >
                          Nøytral
                        </p>
                        <p
                          className={`respons-valg-negativ ${selectedResponse === 'Negativ' ? 'selected' : ''}`}
                          onClick={() => handleResponseClick('Negativ')}
                        >
                          Negativ
                        </p>
                        <p
                          className={`respons-valg-ikke-svar ${selectedResponse === 'IkkeSvar' ? 'selected' : ''}`}
                          onClick={() => handleResponseClick('IkkeSvar')}
                        >
                          Ikke Svar
                        </p>
                      </div>
                      <form className="call-comment-form">
                        <textarea
                          rows="8"
                          cols="80"
                          value={textareaValue}
                          onChange={handleTextareaChange}
                          placeholder="Skriv til kontaktloggen..."
                        />
                        <div className="additional-info-buttons-container">
                        <p
                          className={`submit-btn-kontaktlogg ${selectedResponse !== null ? 'clickable' : 'disabled'}`}
                          onClick={selectedResponse !== null ? () => handleSubmitKontaktLogg(entity) : null}
                        >
                          Lagre i kontaktlogg
                        </p>
                          <p 
                            className="respons-valg-booking"
                            onClick={() => handleMakeBooking(entity)}
                          >
                            Lag Booking
                          </p>
                        </div>
                      </form>
                    </div>
                    

                    <div className="kontakt-logg">
                      {Array.isArray(entity.KontaktLogg) &&
                        entity.KontaktLogg.map((item, index) => {
                          if (index % 3 === 0) {
                            // Timestamp
                            return (
                              <div key={index} className="kontakt-logg-element">
                                <div className="kontakt-logg-header">
                                  <p>{item}</p>
                                  <p>{entity.KontaktLogg[index + 1]}</p>
                                </div>
                                <p className="kontakt-logg-info">{entity.KontaktLogg[index + 2]}</p>
                              </div>
                            );
                          }
                          return null;
                        })}
                    </div>
                  </div>
                </div>
              )}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Ringelister;
