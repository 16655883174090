const translations = {
    no: {
      PrivacyHeader: "Vi verdsetter ditt personvern",
      PrivacyText: "Vi bruker nødvendige informasjonskapsler for å lagre din påloggingsinformasjon og søkeinnstillinger. Disse informasjonskapslene er nødvendige for at nettsiden skal fungere og for å gi en personlig brukeropplevelse. Vi bruker ikke tredjeparts informasjonskapsler eller deler dataene dine med eksterne tjenester.",
      Accept: "Godta",
      Reject: "Avvis"
    },
    en: {
      PrivacyHeader: "We value your privacy",
      PrivacyText: "We use essential cookies to store your login information and search settings. These cookies are necessary for the website to function and to provide a personalized user experience. We do not use third-party cookies or share your data with external services.",
      Accept: "Accept",
      Reject: "Reject"
    },
    pl: {
      PrivacyHeader: "Cenimy Twoją prywatność",
      PrivacyText: "Używamy niezbędnych plików cookie do przechowywania informacji o logowaniu i ustawień wyszukiwania. Te pliki cookie są niezbędne do działania strony internetowej i zapewnienia spersonalizowanego doświadczenia użytkownika. Nie używamy plików cookie stron trzecich ani nie udostępniamy Twoich danych usługom zewnętrznym.",
      Accept: "Akceptuj",
      Reject: "Odrzuć"
    },
    uk: {
      PrivacyHeader: "Ми цінуємо вашу конфіденційність",
      PrivacyText: "Ми використовуємо необхідні файли cookie для збереження вашої інформації для входу та налаштувань пошуку. Ці файли cookie необхідні для функціонування вебсайту і надання персоналізованого досвіду користувача. Ми не використовуємо сторонні файли cookie і не передаємо ваші дані стороннім службам.",
      Accept: "Прийняти",
      Reject: "Відхилити"
    },
    gr: {
      PrivacyHeader: "Wir schätzen Ihre Privatsphäre",
      PrivacyText: "Wir verwenden notwendige Cookies, um Ihre Anmeldeinformationen und Suchfilter zu speichern. Diese Cookies sind für das Funktionieren der Website und eine personalisierte Benutzererfahrung erforderlich. Wir verwenden keine Cookies von Drittanbietern und geben Ihre Daten nicht an externe Dienste weiter.",
      Accept: "Akzeptieren",
      Reject: "Ablehnen"
    }
  };
  
  export default translations;
  