import React from 'react';
import './CookieConsent.css';

import translations from "./translations.js";

const CookieConsentModal = ({ onAccept, onReject, currentLanguage }) => {

    const translateText = (textKey) => {
        // Get the translated text based on the current language
        return translations[currentLanguage][textKey];
      };


  return (

      <div className="modal-content-consent">
        <h2>{translateText("PrivacyHeader")}</h2>
        <p>
            {translateText("PrivacyText")}        
        </p>
        <div className="modal-actions-consent">
          <button className="positiv" onClick={onAccept}>{translateText("Accept")} </button>
          <button className="negativ" onClick={onReject}>{translateText("Reject")} </button>
        </div>
      </div>
    
  );
};

export default CookieConsentModal;